.chipInput {
      border-radius: 6px;
      // padding: 5px;
      // padding: 6px 10px;
      outline: none;
      margin-top: 5px;

      &:focus {
         @apply border border-lightPurple dark:border-darkModeSeparationLine;
      }

      > div {
         margin-top: 5px;
         min-height: 0 !important;
         padding: 2px 4px;
         @apply border border-lightPurple dark:border-darkModeSeparationLine;
         @apply bg-white dark:bg-darkModeDark;
         border-radius: 6px;
      }

      input {
         font-family: 'Poppins', sans-serif;
         font-weight: 300;
         @apply text-dark dark:text-whitish;
         padding: 6px 10px;

         &:focus .chipInput {
           @apply border border-lightPurple dark:border-darkModeSeparationLine #{!important};
         }
      }
}

.chipInput .MuiButtonBase-root {
   @apply bg-brightLightPurple dark:bg-darkPurple;
   @apply text-purple dark:text-white;
   border-radius: 4px;
   transition: .1s ease-in-out;
   margin: 2px 5px 2px 0 !important;

   &:hover {
      @apply bg-purpleTransparent dark:bg-darkModeDeepPurple;
   }
}

.chipInput .MuiButtonBase-root .MuiChip-deleteIcon {
   color: #8992dc;
}

.addWordTeacher {
   display: flex;
   flex-direction: column;
   padding: 0 45px;

   &__content {
      max-height: 550px;
      overflow-y: scroll;
      position: relative;
   }

   &__content::-webkit-scrollbar {
      display: none;
   }

   &__input {
      width: 100%;
      @apply bg-white dark:bg-darkModeDark;
      @apply text-dark dark:text-white;
      @apply border border-lightGray dark:border-darkModeSeparationLine;
      @apply dark:focus:border-lightPurple;

      border-radius: 5px;
      padding: 5px 30px 5px 10px;
      font-weight: 300;
      outline: none;

      &:hover + .addWordTeacher__removeBtn {
         opacity: 1;
         pointer-events: visible;
      }

      &:focus {
         border: 1px solid #bac0e6;
      }
   }

   &__removeBtn {
      position: absolute;
      top: 15px;
      transform: translateY(-50%);
      left: -40px;
      height: 35px;
      width: 35px;
      z-index: 100;
      cursor: pointer !important;
      opacity: 0;
      border-radius: 3px;

      svg {
         width: 20px !important;
         margin: 5px 0 0 7px;
      }

      &:hover {
         background: #ffffff3c;
         opacity: 1;
         pointer-events: visible;
      }
   }

   &__removeBtnIcon {
      color:  #e66767;
   }
}