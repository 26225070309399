@import '../../../variables.scss';

.loader {
   flex-direction: column;
   justify-content: center;
   width: fit-content;
   margin: 0 auto;
   position: absolute;
   right: 15px;
   top: 25px;
 }
 
 .loader__circle {
    font-size: 10px;
    margin: 0 auto;
    text-indent: -9999em;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    @apply bg-darkPurple dark:bg-darkModeDeepPurple;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    // background: -moz-linear-gradient(left, $purple 10%, rgba(255, 255, 255, 0) 42%);
    // background: -webkit-linear-gradient(left, $purple 10%, rgba(255, 255, 255, 0) 42%);
    // background: -o-linear-gradient(left, $purple 10%, rgba(255, 255, 255, 0) 42%);
    // background: -ms-linear-gradient(left, $purple 10%, rgba(255, 255, 255, 0) 42%);
    // background: linear-gradient(to right, $purple 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: loader 1.4s infinite linear;
    animation: loader 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .loader__circle:before {
    width: 50%;
    height: 50%;
    @apply bg-darkPurple dark:bg-darkModeDeepPurple;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  .loader__circle:after {
    @apply bg-darkPurple dark:bg-darkModeDeepPurple;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }


  .addWord {
    &__btn {
      width: 70px;
      @apply bg-darkPurple dark:bg-darkModeDeepPurple;
      color: #fff;
      font-size: 14px;
      position: absolute;
      top: -15px;
      right: 15px;
      border-radius: 5px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transitionFast;

      &:hover {
        background: $hoverPurple;
      }
    }

    &__btn--disabled {
      @extend .addWord__btn;
      @apply bg-lightGray dark:bg-darkModeDisabled;

      pointer-events: none;
    }

    &__langContainerWrapper {
      // border: 1px solid red;
      position: absolute;
      top: 21px;
      right: 95px;
      // width: 100px;
    }
  }
 
  @-webkit-keyframes loader {
   0% {
     -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(360deg);
     transform: rotate(360deg);
   }
 }
 @keyframes loader {
   0% {
     -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(360deg);
     transform: rotate(360deg);
   }
 }
 