.authWrapper {
   position: absolute;
   max-width: 90%;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   @apply text-dark dark:text-white;


   label {
      @apply text-black dark:text-darkModeGray;
   }
   .phoneInput {
      margin-top: 10px;
      font-size: 16px;
      height: fit-content;
      width:  100% !important;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      border: 1px solid transparent;
      @apply bg-white dark:bg-darkModeDark;
      overflow: hidden;

      .PhoneInputCountry {
         padding-left: 20px;
      }

      input {
         padding: 12px 0;
         @apply bg-white dark:bg-darkModeDark;
         outline: none;
      }
   }

   .phoneInput:focus-within {
      @apply border-lightPurple dark:border-darkModeDeepPurple;
    }

   //  label:focus-within {
   //    color: #6a75ca;
   //  }


   .phoneInput--error {
      @extend .phoneInput;
      border: 2px solid #f5b0b5;
   }


   .flag-dropdown {
      border: none;
      background: none;
      padding: 10px;
   }
}

.auth {
   &__input {
      width: 540px;
      @apply bg-white dark:bg-darkModeDark;
      @apply border-white dark:border-transparent;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      padding: 12px 10px;
      margin-top: 8px;
      outline: none;
      border: 1px solid #FFFFFF;
      // @apply border-white dark:bg-darkModeDark;

      
      &:focus {
         // border: 1px solid #b7bdf0;
            @apply border-lightPurple dark:border-darkModeDeepPurple;

      }

      &:focus + label {
         color: #6a75ca;
      }
   }

   &__input--error {
      @extend .auth__input;
      border: 2px solid #f5b0b5;
   }

   &__formAgreement {
      font-size: 13px;
      color: #6a75ca;
      margin-top: 16px;
      position: relative;
      display: flex;
      cursor: pointer;
   }

   &__formAgreementLabel {
      padding-left: 8px;
      cursor: pointer;
      color: #868995;

      span {
         color: #6a75ca;
      }
   }

   &__checkbox {
      opacity: 0;

      &:checked ~ .auth__checkboxCustom {
         background: #6a75ca;
         border: 2px solid #6a75ca;
         
         &:after {
            content: "✓";
            color: #fff;
            font-size: 12px;
            position: absolute;
            top: -3px;
            left: 50%;
            transform: translateX(-50%);
         }
      }
   }

   &__checkboxCustom {
      position: absolute;
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      border:  2px solid #6a75ca93;
      border-radius: 4px;
      pointer-events: none;
   }

   &__textError {
      width: 100%;
      color: #E63946;
      position: absolute;
      text-align: right;
      bottom: -25px;
      font-size: 13px;
   }

   &__agreementErrorText {
      text-align: left;
   }

   &__googleBtn {
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      @apply bg-white dark:bg-darkModeDarkTransparent;
      flex: 1;
      padding: 13px 10px;
      display: flex;
      align-items: center;
      @apply text-gray dark:text-whitish;

      justify-content: center;
      transition: .2s ease-in-out;

      img {
         margin-right: 10px;
         width: 20px;
      }

      &:hover {
         @apply text-textGray dark:text-white;
         @apply bg-white dark:bg-darkModeDark;
         box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
      }
   }

   &__appleBtn {
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      background: #303030;
      flex: 1;
      margin-left: 20px;
      color: #fff;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
         margin-right: 10px;
         width: 20px;
      }
   }

   &__login--error {
      animation: shake 0.5s;
   }


}

.passwordRecoveryCode {
   background: transparent;
   border-bottom: 2px solid rgb(31, 31, 31);
   font-size: 32px;
   margin: 0 12px;
}

.passwordRecoveryCode--focus {
   outline: none;
   border-bottom: 2px solid #6A75CA;
}



