.homePage {

   &__noCollAnimationWrapper {
      width: 100%;
      margin-top: 100px;
   }

   &__noCollAnimation {
      width: 350px;
      margin: 0 auto;
   }
}

.progress-bar {
   width: 350px;
   background: #fff;
   height: 100vh;
   margin-left: auto;
   padding-top: 100px;
   animation: EXPAND-PROGRESSBAR 1s ease-in-out;
}

.collapse-progress {
   width: 140px;
   animation: COLLAPSE-PROGRESSBAR 1s ease-in-out;
}

.progressBar {
   background: #EFF2F8;
   padding: 10px 16px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 20px 20px;
   border-radius: 8px;
   font-size: 14px;
}


@keyframes COLLAPSE-PROGRESSBAR {
   0% { width: 350px; }
   100% { width: 140px; }
}

@keyframes EXPAND-PROGRESSBAR {
   0% { width: 140px; }
   100% { width: 350px; }
}