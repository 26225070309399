@import '../../../variables.scss';

.addStudent {

   &__switchBtn {
      padding: 7px 0;
      font-size: 13px;
      width: 100px;
      text-align: center;
      color: $purple;
      border-radius: 12px;
      cursor: pointer;
      transition: .3s ease-in-out;
      @apply bg-transparent dark:bg-darkModeTransparentPurple;
      @apply border border-lightPurple dark:border-transparent;

      &:hover {
         @apply bg-brightLightPurple dark:bg-darkModeTransparentPurple;
         @apply dark:border-purple;
      }

   }

   &__switchBtn--active {
      @extend .addStudent__switchBtn;
      // background: #d6daff;
      @apply bg-white dark:bg-purple;
      border: 1px solid #d6daff;
      color: $purple;
      pointer-events: none;
   }

   &__copyLinkBtn {
      position: absolute;
      bottom: 6px;
      right: 5px;
   }
}