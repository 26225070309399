.editCollection {
   &__input {
      @apply border border-lightGray dark:border-darkModeSeparationLine;
      @apply bg-white dark:bg-darkModeDark;
      border-radius: 7px;
      padding: 10px 7px;
      outline: none;
      margin-top: 5px;
      font-weight: 300;

      &:focus {
         border: 1px solid #bac0e6;
      }

      &::placeholder {
         opacity: 0.3;
         font-size: 14px;
      }
   }

   &__inputAutocomplete {
      @extend .editCollection__input;
      margin-top: 5px !important;
   }

   .MuiAutocomplete-inputRoot {
      input {
         outline: none;
         @apply text-dark dark:text-whitish;
      }
      
      input::placeholder {
         font-size: 15px;
      }
   }

   .MuiAutocomplete-inputRoot.MuiOutlinedInput-root{
      padding: 5px 5px 4px 5px;
   }
   
   .MuiOutlinedInput-notchedOutline {
      @apply border border-lightGray dark:border-darkModeSeparationLine;
      margin-top: 5px;
      border-radius: 7px;
   }

   .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      @apply border border-lightPurple dark:border-darkModeDeepPurple;
      border-width: 1px;
   }
      
   .MuiOutlinedInput-notchedOutline {
      margin-top: 0;
   }
}
