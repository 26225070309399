.tagsInput {
    @apply border border-lightGray dark:border-darkModeSeparationLine;
    @apply bg-white dark:bg-darkModeDark;
    @apply text-dark dark:text-whitish;
    border-radius: 6px;
    padding: 0 10px;
    padding-top: 1px;
    font-weight: 300;
    margin-top: 5px;
  
    input {
        margin-top: -7px;
        width: 400px;
    }
}

.tagsInput:focus-within {
    // border: 1px solid #bac0e6;
    border: #2828286d;
    @apply border border-lightPurple dark:border-darkModeDeepPurple;

}