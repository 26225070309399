@import '../../../variables.scss';

.load {

   &__item {
      border: 1px solid lighten($purple, 20%);
      font-size: 12px;
      padding: 7px 20px;
      border-radius: 6px;
      cursor: pointer;
      transition: .1s ease-in-out;
      margin: 4px;
      flex: 1;
      text-align: center;
      white-space: nowrap;
      @apply border dark:border-darkModeDeepPurple text-textDarkGray dark:text-white bg-whiteTransparent dark:bg-darkModeTransparentPurple text-purple;

      &:hover {
        @apply bg-white dark:bg-darkModeDeepPurple;
         border: 1px solid $purple;
      }  
   }

   &__item--active {
         @extend .load__item;
         border: 1px solid $purple;
         @apply text-white bg-darkPurple dark:bg-darkModeDeepPurple;

         &:hover {
            @apply bg-purple dark:bg-darkModeDeepPurple;
          }  


   }

   &__item--disabled {
      @extend .load__item;
      pointer-events: none;
      @apply bg-transparent dark:bg-darkModeDisabled;
      @apply border border-gray dark:border-transparent;
      @apply text-textDarkGray;
   }


   &__selectCollection {
      border: 1px solid #97a0e4;
      color: $purple;
      font-size: 12px;
      padding: 7px 20px;
      border-radius: 6px;
      cursor: pointer;
      transition: .1s ease-in-out;
      margin: 4px;
      flex: 1;
      text-align: center;
      white-space: nowrap;
      background: #ffffff;

      &:hover {
         background: #eef0ff;
      }  
   }

   &__selectCollection--active {
      @extend .load__selectCollection;
      border: 1px solid $purple;
      background: $purple;
      color: #fff;
   }
}
