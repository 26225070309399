@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./pages/homePage/homePage.scss"; 
@import "./components/header/header.scss"; 
@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  @apply bg-bgLightMode dark:bg-bgDarkMode;

}

.moreActionBtn--open {
  color: $dark;
  transition: $transition;
  cursor: pointer;

  &:hover {
    color: $purple;
  }
}

.moreActionBtn--open-Active {
  color: #fff;
  cursor: pointer;
}

.contentWrapper {
  // background: #EFF2F8;
  flex: 1;
  // position: relative;
  display: flex;
  flex-direction: column;
}

// popups 

.MuiDialog-container .MuiPaper-root {
  box-shadow: none !important;
  background-color: transparent !important;
}

.MuiPaper-root .addWord {
  background-color:transparent;
}

.MuiPaper-root .MuiDialogContent-root.dialog.addWord {
  background-color: transparent !important;
  box-shadow: none !important;
}

.MuiPaper-root .MuiDialogContent-root.dialog {
  background-color: #ffffff;
  border-radius: 12px !important;
}

.MuiBackdrop-root {
  @apply bg-bgPopupLightMode dark:bg-bgPopupDarkMode #{!important};
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.MuiDialog-paper {
  border-radius: 12px !important;
  width: 625px;
}

.dialog .MuiDialogContent-root {
  &:first-child {
     padding: 0;
  }
}
.dialog .MuiDialogContent-root {
  &:nth-child(2){
     border-radius: 12px !important;
  }
}


.dialog--blurred {
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px);

}

// .customBorder {
//   @apply border border-transparent dark:border-darkModeSeparationLine;
//   border-radius: 20px;
// }
// ripple

.MuiTouchRipple-root {
  color: rgba(255, 255, 255, 0.788);
}

// common styles

.popupInput {
  @apply bg-white dark:bg-darkModeDark;
  @apply border border-lightPurple dark:border-darkModeSeparationLine;
  @apply text-dark dark:text-whitish;
  border-radius: 6px;
  padding: 6px 10px;
  font-weight: 300;
  margin-top: 5px;
  outline: none;

  &:focus {
    @apply border border-lightPurple dark:border-darkModeDeepPurple;
  }
}

.popupInput--error {
  @extend .popupInput;
  border: 1px  solid #f5b0b5;
}


// cropper 

.reactEasyCrop_Container {
  height: 160px;
  width: 160px;
  margin: 0 auto;

  img {
    max-height: none;
  }

  .reactEasyCrop_CropArea {
    height: 160px !important;
    width: 160px !important;
    @apply text-white dark:text-darkModeDark;
  }
}


// animation when we switching between pages

.fadeIn {
  animation: fadeIn .5s ease-in-out;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


// loader for pages 

.pageLoader {
  width: 80px;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dataLoader {
  width: 80px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 30px;
}

// loader logo animation

.animatedLoaderLogoWrapper {
  position: fixed;
  left: calc(60px + 50%);
  bottom: 80px;
}

.animatedLoaderLogo {
  width: 80px;
  opacity: 0.4;
  animation: ease-in-out 1.6s infinite loaderLogo;
}

@keyframes loaderLogo {
  0% {
    opacity: 0.4;
    transform: scale(0.8);

  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.4;
    transform: scale(0.8);
  }
}

// error
.text--error {
  color: #E63946;
  font-size: 12px;
}

.MuiAutocomplete-paper {
  @apply bg-white dark:bg-darkModeDark #{!important};
  @apply border border-transparent dark:border-darkModeSeparationLine;
}

.halfTransparentArea {
  @apply bg-gradient-to-b from-transparent to-white  dark:from-transparent dark:to-darkModeDark;
}

// selector inputs Material ui 
.MuiAutocomplete-clearIndicator, .MuiAutocomplete-popupIndicator {
  @apply text-darkModeDark dark:text-white #{!important};
}


@keyframes shake {
  25% {
      left: 20px;
  } 

  50% {
      left: 0px;
      right: 20px;
  }

  75% {
      right: 0px;
      left: 20px;
  }

  100% {
      left: 0px;
      right: 20px;
  }
}